<template>
	<edit-template class="role-edit" ref="edit">
		
    <!-- 大表单 -->
    <div class="form-body">
      <el-form ref="form" :model="form" :rules="rules" label-width="150rem" style="width: 500rem">
        <el-form-item label="选择校区" prop="school_id">
          <!-- <el-select :popper-append-to-body="false" v-model="form.school_id" placeholder="请选择校区" :disabled="disabled">
						<el-option v-for="item in campusArr" :label="item.school_name" :value="parseInt(item.id)"></el-option>
					</el-select> -->
          {{ form.school }}
        </el-form-item>

        <el-form-item label="选择楼宇" prop="building_id">
          <el-select :popper-append-to-body="false" v-model="form.building_id" placeholder="请选择楼宇" @change="changeBuilding" :disabled="disabled">
            <el-option v-for="(item, index) in buildingArr" :label="item.dimicile_buliding_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择楼层" prop="storey">
          <el-select :popper-append-to-body="false" v-model="form.storey" placeholder="请选择楼层" :disabled="disabled">
            <el-option v-for="item in floorArr" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="辅导地点名称" prop="addr_name">
          <el-input placeholder="请输入辅导地点名称" v-model="form.addr_name" :disabled="disabled"></el-input>
        </el-form-item>

        <el-form-item label="辅导科目" prop="subject_ids">
          <!-- <el-select v-model="form.subject_ids" multiple placeholder="请选择辅导科目" :disabled="disabled">
						<el-option v-for="item in subjectsArr" :key="item.id" :label="item.subject_name" :value="item.id"> </el-option>
					</el-select> -->
          {{ form.subject }}
        </el-form-item>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    <template slot="footer">
      <el-button type="primary" @click="save('form')" style="margin-left: 50rem" v-if="!disabled">保存</el-button>
      <el-button class="lowerbtn" @click="open(disabled)">{{ disabled == true ? '返回' : '取消' }}</el-button>
    </template>
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
    return {
      disabled: false,
      form: {storey: '', subject_ids: [], addr_name: ''},
      campusArr: [],
      buildingArr: [],
      floorArr: [],
      subjectsArr: [],
      rules: {
        school_id: [{required: true, message: '请选择校区', trigger: 'change'}],
        building_id: [{required: true, message: '请选择楼宇', trigger: 'change'}],
        storey: [{required: true, message: '请选择楼层', trigger: 'change'}],
        addr_name: [{required: true, message: '请输入辅导地点', trigger: 'change'}],
        subject_ids: [{required: true, message: '请选择辅导科目', trigger: 'change'}]
      },
      options: []
    }
  },

  methods: {
    save(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$_axios2
              .post('api/1v1/place/' + this.$route.query.id, this.form)
              .then((res) => {
                if (res.data.status == 0) {
                  this.$message({
                    type: 'success',
                    message: '修改成功!'
                  })
                  this.$router.back()
                }
              })
              .catch(() => {
              })
        }
      })
    },
    open(val) {
      if (val) {
        this.$router.back()
      } else
        this.$confirm('是否取消编辑', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.back()
        })
    },
    changeBuilding(val) {
      this.floorArr = []
      this.form.storey = ''
      this.buildingArr.forEach((item, index) => {
        if (val == item.id) {
          for (let index = item.floor_min; index <= item.floor_max; index++) {
            this.floorArr.push(index)
          }
        }
      })
    }
  },

  created() {
    this.$_axios2.get('api/1v1/place/' + this.$route.query.id).then(res => {
      this.form = res.data.data
      this.form.subject_ids = this.form.subject_ids.split(',')
      this.$_axios2.get('api/1v1/place/get-building?school_id=' + this.form.school_id).then(res => {
        this.buildingArr = res.data.data
        this.buildingArr.forEach(item => {
          if (this.form.building_id == item.id) {
            for (let index = item.floor_min; index <= item.floor_max; index++) {
              this.floorArr.push(index)
            }
          }
        })
      })
    })

    this.$_axios.get('site/school').then(res => {
      this.campusArr = res.data.data
    })
    this.$_axios.get('site/fist-subject').then(res => {
      this.subjectsArr = res.data.data
    })
    if (this.$route.query.type == 0) {
      this.disabled = true
      for (const key in this.rules) {
        this.rules[key][0].required = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
